
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';

  export default defineComponent({
    name: 'about-us-overview',

    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('aboutUs'), [
          translate('settings'),
        ]);
      });

      await store.dispatch(Actions.GET_ABOUT_US);
      const loading = ref(false);

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        aboutUs: computed(() => store.getters.AboutUsView),
        translate,
        goTo,
        loading,
        can,
      };
    },
  });
